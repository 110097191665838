import React from "react";
import "./ProgressBar.scss";
import Bar from "../Bar";
import { HGTooltip } from "common/HGTooltip";
interface ProgressBarProps {
  percent?: number;
  data?: number[];
  max?: number;
  className?: string;
  valueField?: (x: any) => string;
  color?: string;
}

export const requireClickableFields = (
  filteredFormatting,
  input: String | Array<String>,
  filterFn: () => void
): boolean | (() => void) => {
  if (Array.isArray(input)) {
    return (
      input.every((i) => filteredFormatting.some((d) => d.name == i)) &&
      filterFn
    );
  } else {
    return filteredFormatting.some((d) => d.name == input) && filterFn;
  }
};

export const ProgressBar = (props: ProgressBarProps | any) => {
  if (props.percent) {
    return <Bar data={props.percent} max={100} />;
  }
  if (props.data) {
    let base = 0;
    let max =
      props.max ||
      props.data
        .map((d) => (props.valueField ? props.valueField(d) : d))
        .reduce((a: any, b: any) => a + b, 0) ||
      100;

    return (
      <div>
        <div style={{ display: "none" }} />
        <svg
          className={`${props.className || ""} autoFade progressBar`}
          width="100%"
          height={props.height || "25px"}
        >
          {props.data.map((d) => {
            let value = props.valueField ? props.valueField(d) : d;
            let width = (100 * value) / max;
            let xPos = base;
            base = xPos + width;
            let textPos = width > 50 ? width - 2 : width + 2;

            const tooltip =
              props.tooltip?.(d) ||
              (!props.onClick && props.disabledTooltip?.(d)) ||
              null;
            return d?.name !== "Closed" ? (
              <HGTooltip tooltip={tooltip}>
                <g transform={`translate(${xPos},0)`}>
                  <rect
                    style={{
                      strokeWidth: "1",
                      stroke: "white",
                      fill: !props.useClass ? props.color : undefined,
                      cursor: props.onClick ? "pointer" : "not-allowed",
                    }}
                    height="100%"
                    width={`${width}%`}
                    onClick={props.onClick || null}
                  />
                  <text
                    style={{
                      fill:
                        width > 50
                          ? "white"
                          : !props.useClass
                          ? props.color
                          : undefined,
                      cursor: props.onClick ? "pointer" : "not-allowed",
                    }}
                    className="status-label"
                    fontWeight="bolder"
                    y="50%"
                    x={`${textPos}%`}
                    textAnchor={width > 50 ? "end" : "start"}
                    alignmentBaseline="middle"
                    onClick={props.onClick || null}
                  >
                    {value}
                  </text>
                </g>
              </HGTooltip>
            ) : (
              <HGTooltip tooltip={tooltip}>
                <text
                  style={{
                    cursor: props.onClick ? "pointer" : "not-allowed",
                  }}
                  className="status-label"
                  fontWeight="bolder"
                  y="50%"
                  alignmentBaseline="middle"
                  onClick={props.onClick || null}
                >
                  {value}
                </text>
              </HGTooltip>
            );
          })}
        </svg>
      </div>
    );
  }
  throw new Error("Invalid data input");
};
