import React from "react";
import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import { formatStatus } from "utils/formatStatus";

const getDueDateIcon = (item) => {
  if (item.status === "Closed") return null;

  if (item.dueDate) {
    const today = new Date();
    const dueDate = new Date(item.dueDate);
    if (dueDate < today) {
      return (
        <FontAwesomeIcon
          icon={faExclamationCircle}
          style={{ color: "red", marginRight: "5px" }}
        />
      );
    }

    if (
      dueDate.getMonth() === today.getMonth() &&
      dueDate.getFullYear() === today.getFullYear()
    ) {
      return (
        <FontAwesomeIcon
          icon={faCalendarAlt}
          style={{ color: "orange", marginRight: "5px" }}
        />
      );
    }
  } else {
    return (
      <FontAwesomeIcon
        icon={faCalendarAlt}
        style={{ color: "gray", marginRight: "5px" }}
      />
    );
  }

  return null;
};

const getTooltip = (item, display) => {
  if (display === "actionplan") {
    if (item.status === "Closed")
      return `${item.title} (${formatStatus(item.status)})`;

    if (item.dueDate) {
      const today = new Date();
      const dueDate = new Date(item.dueDate);

      if (dueDate < today) {
        return `${item.title} (${formatStatus(item.status)}) (Past Due)`;
      }

      if (
        dueDate.getMonth() === today.getMonth() &&
        dueDate.getFullYear() === today.getFullYear()
      ) {
        return `${item.title} (${formatStatus(item.status)}) (Due Soon)`;
      }
    } else {
      return `${item.title} (${formatStatus(item.status)}) (No Due Date Set)`;
    }
    return `${item.title} (${formatStatus(item.status)})`;
  }
  return `${item.title} (${formatStatus(item.status)})`;
};

export const RelatedItemBadge = ({ items, display, filter = "", url = "" }) => {
  if (filter !== "") items = items.filter((a) => a.targetType === filter);
  if (
    display === "issue" ||
    display === "actionplan" ||
    display === "scenarios"
  ) {
    return (
      <>
        {items.map((item) => {
          return (
            <Link to={`/${url !== "" ? url : filter}/${item.item.id}`}>
              <Badge
                pill
                className={`${item.item.status} releted_item`}
                title={getTooltip(item.item, display)}
              >
                {display === "actionplan" && getDueDateIcon(item.item)}
                {item.item.title.length > 30
                  ? `${item.item.title.substring(0, 30 - 3)}...`
                  : item.item.title}
              </Badge>
            </Link>
          );
        })}
      </>
    );
  } else if (display === "task") {
    return (
      <>
        {items.map((item) => {
          return (
            <Link to={`/actionplans/${item.id}/tasks/${item.task.id}`}>
              <Badge
                pill
                className={`${item.task.status} releted_item`}
                title={`${item.task.title} (${itemStatus(
                  `${item.task.status}`
                )})`}
              >
                {item.task.title.length > 30
                  ? `${item.task.title.substring(0, 30 - 3)}...`
                  : item.task.title}
              </Badge>
            </Link>
          );
        })}
      </>
    );
  }
  return null;
};

const itemStatus = (status) => {
  let title = "";
  switch (status) {
    case "In_Progress":
      title = "In Progress";
      break;
    case "Closed_Pending_Approval":
      title = "Closed (Pending Approval)";
      break;
    default:
      title = status;
      break;
  }
  return title;
};
