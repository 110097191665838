import React from "react";
import { ExceedanceGraphSVG, BoxPlotSVG } from "common/fair-graphs";
import { graphToPngUri } from "common/fair-graphs/graphToBase64";
import { zipSum } from "utils/stats";

export const getBaseData = (data) => data?.magnitudes ?? [];

export const getTotalAggregate = (
  data
): Array<{ title: string; data: Array<number> }> =>
  (data &&
    data?.length > 0 && [
      {
        title: "Total",
        data: data.length === 0 ? [[]] : zipSum(...data.map((r) => r.data)),
      },
    ]) ||
  [];

export const createExceedanceGraphBase64 = async (data) => {
  const exceedenceGraph = await graphToPngUri(
    <ExceedanceGraphSVG
      height={350}
      width={500}
      data={data}
      xFormat={(t) => `$${t}`}
      yFormat={(t) => `${t}%`}
      multiple
    />
  );
  return exceedenceGraph.replace("data:image/png;base64,", "");
};

export const createBoxPlotBase64 = async (data) => {
  const boxPlot = await graphToPngUri(
    <BoxPlotSVG height={350} width={500} data={data} yFormat={(t) => `${t}%`} />
  );
  return boxPlot.replace("data:image/png;base64,", "");
};
