import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import GenerateReport from "utils/docx-export/GenerateReport";
import { useItemEditContext } from "./bulkEdit";
import { DateTime } from "luxon";
import { useScenarioComparisonContext } from "../Modules/Customers/Risk/Comparison/TableComparison";
import { HGForm } from "./HGForm";
import { HGInputBox } from "./HGForm/Inputs";

const BulkExport = ({ items, schema }) => {
  const [show, setShow] = useState(false);
  const dateTime = DateTime.now().toFormat("MMddyy-HHmm");
  const [fileName, setFileName] = useState(
    `DecipherRisk-${items[0]?.__typename.replace(
      /([A-Z][a-z]*)(?=[A-Z])/,
      "$1 "
    )}-${dateTime}`
  );
  const itemEdit = useItemEditContext();
  const scenarioComparison = useScenarioComparisonContext();
  return (
    <>
      <Button
        className="Modify-text"
        variant="light"
        onClick={() => setShow(true)}
      >
        Export to Docx
      </Button>
      <Modal show={show}>
        <HGForm>
          <Modal.Header>Bulk Export</Modal.Header>
          <Modal.Body>
            <HGInputBox
              name="filename"
              label="File Name"
              placeholder="Enter file name"
              defaultValue={fileName}
              required
              onChange={(e) => setFileName(e.target.value?.trim())}
            />
            {!fileName && (
              <>
                <p className="text-danger">File Name is a required field</p>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <GenerateReport
              type="Multi"
              schema={schema()}
              items={items.filter((obj) =>
                (items[0]?.__typename === "Scenario"
                  ? scenarioComparison
                  : itemEdit
                ).checked?.includes(obj.id)
              )}
              fileName={fileName}
              tableOfContents
            />
            <Button
              variant="light"
              className="cancel-btn"
              onClick={() => setShow(false)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </HGForm>
      </Modal>
    </>
  );
};

export default BulkExport;
