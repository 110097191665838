import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Dropdown,
  Modal,
  Row,
} from "react-bootstrap";
import HGConfirmationModal from "./HGConfirmationModal";
import { usePermissions } from "./Permissions";
import { useParams } from "react-router";
import { HGForm, HGFormSubmit } from "./HGForm";
import { HGInputBox } from "./HGForm/Inputs";
import { cancelPopupMessage } from "utils/globalVariables";
const ConvertItem = ({
  item,
  title,
  updateItemMutation,
  templateMutation,
  cloneMutation,
  itemSchema,
}) => {
  const { isTemplate } = item;
  const { id } = useParams<{ id: string }>();
  const { group } = usePermissions();
  const [showIssueTemplate, setShowIssueTemplate] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const handleYes = async () => {
    await updateItemMutation({
      variables: {
        input: {
          id,
          groupID: group.id,
          isTemplate: isTemplate === true ? false : true,
        },
      },
    });
    setShowModal(false);
  };
  function Confirmation() {
    if (window.confirm(cancelPopupMessage)) {
      setShowIssueTemplate(false);
    }
  }
  const getItemType = (typename) => {
    switch (typename) {
      case "AuditPlanning":
        return "Audit";
      case "ActionPlan":
        return "Action Plan";
      default:
        return typename;
    }
  };
  return (
    <>
      <Dropdown as={ButtonGroup} style={{ paddingBottom: "10px" }}>
        <Button variant="primary" onClick={() => setShowIssueTemplate(true)}>
          {title}
        </Button>
        <Dropdown.Toggle split variant="primary" id="dropdown-split-basic">
          <div style={{ float: "right" }}>
            <b className="caret" />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => setShowModal(true)}>
            {item?.isTemplate === true
              ? `Convert to ${getItemType(item.__typename)}`
              : "Convert to Template"}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <HGConfirmationModal
        title={
          item?.isTemplate !== true
            ? `Are you sure you want to convert item into a Template?`
            : `Are you sure you want to convert template into ${getItemType(
                item.__typename
              )}`
        }
        show={showModal}
        body={
          item?.isTemplate !== true
            ? `Are you sure you want to convert ${item.title} into a Template?`
            : `Are you sure you want to convert ${
                item.title
              } into a ${getItemType(item.__typename)}`
        }
        onNo={() => setShowModal(false)}
        onYes={handleYes}
      />
      <Modal
        key="IssueTemplateModal"
        show={!!showIssueTemplate}
        onHide={() => {
          Confirmation();
        }}
      >
        <HGForm
          key="form"
          onSubmit={async (input) => {
            if (item.isTemplate === true) {
              return cloneMutation({
                variables: { input: { ...input, isTemplate: false } },
              });
            }

            return templateMutation({
              variables: { input: { ...input } },
            });
          }}
          resolver={itemSchema}
        >
          <Modal.Header>
            {item?.isTemplate === true
              ? `Clone ${item.__typename}`
              : "Create Template"}
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={12}>
                <HGInputBox name="title" label="Title" />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn cancel-btn"
              onClick={() => {
                Confirmation();
              }}
              variant="light"
            >
              Cancel
            </Button>
            <HGFormSubmit />
          </Modal.Footer>
        </HGForm>
      </Modal>
    </>
  );
};

export default ConvertItem;
