import { ReportConfig } from "utils/docx-export/reportSchemaParse.types";
import { useGenerateReportFilename } from "utils/docx-export/useCreateFilename";
import { ScenarioType } from "./scenarioTypes";
import { DateTime } from "luxon";
import { formatStatus } from "utils/formatStatus";
import { usePermissions } from "common/Permissions";
import {
  useGetGroupByRelatedItem,
  relatedItemsParse,
} from "utils/useGetGroupByRelatedItems";
import {
  createFAIRSummaryData,
  createFAIRSummaryTable,
  getAnalysisScope,
} from "./riskReportUtils";
import { AlignmentType, Paragraph, TextRun, ImageRun } from "docx";
import {
  createExceedanceGraphBase64,
  getBaseData,
  getTotalAggregate,
  createBoxPlotBase64,
} from "./riskImageUtils";
import { getFairData } from "../FAIR/Summary/FairSummary";

export const scenarioReportSchema = (): ReportConfig<ScenarioType> => {
  const getFilename = useGenerateReportFilename();
  const { getOrganizationUser, groups } = usePermissions();

  return {
    getFilename: (item) =>
      getFilename({
        type: "filename",
        fileName: `Decipher Risk - Risk Scenario ${item.title ?? "title"}`,
        timestamp: DateTime.now(),
      }),
    fields: [
      { type: "Header", content: () => "Risk Scenario" },
      { type: "Title", content: (item) => item.title ?? "" },
      {
        type: "InlineText",
        label: "Status",
        content: (item) => formatStatus(item?.status),
      },
      {
        type: "InlineText",
        label: "Created At",
        content: (item: any) =>
          DateTime.fromISO(item?.createdAt)
            .toLocaleString(DateTime.DATETIME_MED)
            .replace(/,/g, "") ?? "",
      },
      {
        type: "InlineText",
        label: "Last Modified",
        content: (item: any) =>
          DateTime.fromISO(item?.lastModified)
            .toLocaleString(DateTime.DATETIME_MED)
            .replace(/,/g, ""),
      },
      {
        type: "InlineText",
        label: "Owner",
        content: (item) => getOrganizationUser(item?.owner)?.displayName,
      },
      {
        type: "InlineText",
        label: "Creation Date",
        content: (item) => item?.creationDate,
      },
      {
        type: "InlineText",
        label: "Last Revision",
        content: (item) => item?.lastRevision,
      },
      {
        type: "InlineText",
        label: "Next Revision",
        content: (item) => item?.nextRevision,
      },
      {
        type: "RichText",
        label: "Description",
        content: (item) => item.description ?? "",
      },
      {
        type: "RichText",
        label: "Notes",
        content: (item) => item.notes ?? "",
      },
      {
        type: "RichText",
        label: "Assumptions",
        content: (item) => item.assumptions ?? "",
      },
      {
        type: "InlineText",
        label: "Indicator",
        content: (item) => item.indicator ?? "",
      },
      {
        type: "Custom",
        label: "",
        formatting: (item) => [
          new Paragraph({
            alignment: AlignmentType.LEFT,
            children: [
              new TextRun({ text: "Analysis Scope", bold: true, break: 1 }),
            ],
          }),
          ...getAnalysisScope(item),
        ],
      },
      {
        type: "Custom",
        label: "",
        formatting: (item) => [
          new Paragraph({
            alignment: AlignmentType.LEFT,
            children: [
              new TextRun({
                text: "FAIR Summary",
                bold: true,
                break: 1,
              }),
            ],
          }),
          createFAIRSummaryTable(createFAIRSummaryData(item)),
        ],
      },
      {
        type: "RelatedItems",
        label: "Related Items",
        formatting: (item) => [
          new Paragraph({
            alignment: AlignmentType.LEFT,
            heading: "Heading2",
            children: [
              new TextRun({ text: "Related Items", bold: true, break: 1 }),
            ],
          }),
          ...relatedItemsParse(
            useGetGroupByRelatedItem(item?.scenarioRelatedItems?.items, groups)
          ),
        ],
      },
      {
        type: "CustomAsync",
        label: "IMAGE THING",
        formatting: async (item) => {
          const data = getFairData(item);
          const baseData = getBaseData(data);
          const aggregateData = getTotalAggregate(baseData);
          const exceedenceGraph = await createExceedanceGraphBase64([
            ...aggregateData,
            ...baseData,
          ]);
          const boxplotGraph = await createBoxPlotBase64(baseData);
          return [
            new Paragraph({
              alignment: AlignmentType.LEFT,
              children: [
                new TextRun({ text: "Exceedance Graph", bold: true }),
                new ImageRun({
                  data: Uint8Array.from(atob(exceedenceGraph), (c) =>
                    c.charCodeAt(0)
                  ),
                  transformation: {
                    width: 500,
                    height: 400,
                  },
                }),
                new TextRun({ text: "Box Plot Graph", bold: true, break: 1 }),
                new ImageRun({
                  data: Uint8Array.from(atob(boxplotGraph), (c) =>
                    c.charCodeAt(0)
                  ),
                  transformation: {
                    width: 500,
                    height: 400,
                  },
                }),
              ],
            }),
          ];
        },
      },
    ],
  };
};
